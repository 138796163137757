// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-head-about-me-mdx": () => import("./../../../src/pages/head_about_me.mdx" /* webpackChunkName: "component---src-pages-head-about-me-mdx" */),
  "component---src-pages-head-my-plan-for-next-year-mdx": () => import("./../../../src/pages/head_my_plan_for_next_year.mdx" /* webpackChunkName: "component---src-pages-head-my-plan-for-next-year-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-mayb-gratitude-for-teachers-mdx": () => import("./../../../src/pages/mayb_gratitude for teachers.mdx" /* webpackChunkName: "component---src-pages-mayb-gratitude-for-teachers-mdx" */),
  "component---src-pages-norm-2021-state-of-dances-mdx": () => import("./../../../src/pages/norm_2021 state of dances.mdx" /* webpackChunkName: "component---src-pages-norm-2021-state-of-dances-mdx" */),
  "component---src-pages-norm-accommodating-different-types-of-people-mdx": () => import("./../../../src/pages/norm_Accommodating different types of people.mdx" /* webpackChunkName: "component---src-pages-norm-accommodating-different-types-of-people-mdx" */),
  "component---src-pages-norm-being-more-technology-focused-mdx": () => import("./../../../src/pages/norm_being more technology focused.mdx" /* webpackChunkName: "component---src-pages-norm-being-more-technology-focused-mdx" */),
  "component---src-pages-norm-better-access-to-clubs-mdx": () => import("./../../../src/pages/norm_better access to clubs.mdx" /* webpackChunkName: "component---src-pages-norm-better-access-to-clubs-mdx" */),
  "component---src-pages-norm-centralizing-communications-in-leadership-mdx": () => import("./../../../src/pages/norm_Centralizing communications in leadership.mdx" /* webpackChunkName: "component---src-pages-norm-centralizing-communications-in-leadership-mdx" */),
  "component---src-pages-norm-collaborating-with-other-schools-mdx": () => import("./../../../src/pages/norm_collaborating with other schools.mdx" /* webpackChunkName: "component---src-pages-norm-collaborating-with-other-schools-mdx" */),
  "component---src-pages-norm-creating-a-sense-of-community-at-irvine-mdx": () => import("./../../../src/pages/norm_creating a sense of community at irvine.mdx" /* webpackChunkName: "component---src-pages-norm-creating-a-sense-of-community-at-irvine-mdx" */),
  "component---src-pages-norm-doing-more-town-halls-mdx": () => import("./../../../src/pages/norm_doing more town halls.mdx" /* webpackChunkName: "component---src-pages-norm-doing-more-town-halls-mdx" */),
  "component---src-pages-norm-having-consistent-school-events-mdx": () => import("./../../../src/pages/norm_Having Consistent School Events.mdx" /* webpackChunkName: "component---src-pages-norm-having-consistent-school-events-mdx" */),
  "component---src-pages-norm-holding-asb-accountable-mdx": () => import("./../../../src/pages/norm_holding ASB accountable.mdx" /* webpackChunkName: "component---src-pages-norm-holding-asb-accountable-mdx" */),
  "component---src-pages-norm-how-we-can-make-these-ideas-happen-mdx": () => import("./../../../src/pages/norm_how we can make these ideas happen.mdx" /* webpackChunkName: "component---src-pages-norm-how-we-can-make-these-ideas-happen-mdx" */),
  "component---src-pages-norm-how-we-should-manage-information-mdx": () => import("./../../../src/pages/norm_how we should manage information.mdx" /* webpackChunkName: "component---src-pages-norm-how-we-should-manage-information-mdx" */),
  "component---src-pages-norm-linking-iva-and-hybrid-mdx": () => import("./../../../src/pages/norm_linking IVA and hybrid.mdx" /* webpackChunkName: "component---src-pages-norm-linking-iva-and-hybrid-mdx" */),
  "component---src-pages-norm-looking-in-the-past-for-ideas-mdx": () => import("./../../../src/pages/norm_looking in the past for ideas.mdx" /* webpackChunkName: "component---src-pages-norm-looking-in-the-past-for-ideas-mdx" */),
  "component---src-pages-norm-making-asb-more-diverse-mdx": () => import("./../../../src/pages/norm_making ASB more diverse.mdx" /* webpackChunkName: "component---src-pages-norm-making-asb-more-diverse-mdx" */),
  "component---src-pages-norm-prioritizing-wellness-mdx": () => import("./../../../src/pages/norm_prioritizing wellness.mdx" /* webpackChunkName: "component---src-pages-norm-prioritizing-wellness-mdx" */),
  "component---src-pages-norm-showcasing-all-sports-equally-mdx": () => import("./../../../src/pages/norm_showcasing all sports equally.mdx" /* webpackChunkName: "component---src-pages-norm-showcasing-all-sports-equally-mdx" */),
  "component---src-pages-norm-showing-gratitude-towards-teachers-mdx": () => import("./../../../src/pages/norm_showing gratitude towards teachers.mdx" /* webpackChunkName: "component---src-pages-norm-showing-gratitude-towards-teachers-mdx" */)
}

